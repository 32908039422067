import {Action} from 'redux-actions'
import {takeLatest, call, put, select, takeLeading} from 'redux-saga/effects'
import _ from 'lodash'

import {AccountAPI} from '@/service'
import {Enums} from '@/constants'
import {UIActions, AccountActions, TableConfigActions} from '@/actions'
import {history} from '@/routes'

import {AccountConstants} from '../constants'

function* register({payload = {}}: any) {
  try {
    const {onSuccess, ...values} = payload
    yield call(AccountAPI.register, values)
    yield put(UIActions.showMessage({type: 'success', message: '登録が成功する'}))
    onSuccess?.()
    history.push('/account/register/temporary/success')
  } catch (err) {}
}

function* upgradeMe({payload = {}}: any) {
  try {
    const {account} = payload
    yield call(AccountAPI.upgradeMe, {account})

    history.push('/account/register/formal/success')
  } catch (err) {}
}

function* verifyUpgradeMe({payload = {}}: any) {
  try {
    const {account, onSuccess} = payload
    yield call(AccountAPI.verifyUpgradeMe, {account})
    onSuccess?.()
  } catch (err) {}
}
function* getMe() {
  try {
    const res = yield call(AccountAPI.getMe)
    yield put(AccountActions.setMe({account: res.account, legalEntity: res.legalEntity}))
  } catch (err) {}
}
function* changeMyEmail({payload = {}}: Action<any>) {
  try {
    const {email} = payload
    yield call(AccountAPI.changeMyEmail, {email})
    yield put(
      UIActions.showMessage({
        type: 'success',
        message: '入力いただいたメールアドレス宛てにメールアドレスリセットメール送付しました',
      })
    )
  } catch (error) {}
}
function* verifyEmail({payload = {}}: Action<any>) {
  const {token, verifyType, onSuccess, onFail} = payload
  try {
    yield call(AccountAPI.emailVerify, {verifyType, token})
    yield put(UIActions.showMessage({type: 'success', message: 'メールアドレスの認証に成功しました'}))
    onSuccess?.()
  } catch (error) {
    onFail?.()
  }
}
function* listAccountCreation() {
  try {
    const paging = yield select((state) =>
      _.get(state, ['tableConfig', AccountConstants.LIST_ACCOUNT_CREATION, 'paging'], {})
    )
    const res = yield call(AccountAPI.listAccountCreation, {
      paging,
      status: Enums.AccountCreationStatusEnum.AwaitingCheck,
    })
    yield put(AccountActions.setListAccountCreation({accounts: res.accounts}))
    yield put(
      TableConfigActions.setTableConfig({
        type: AccountConstants.LIST_ACCOUNT_CREATION,
        config: {pagination: {total: res.paging?.totalNumber}},
      })
    )
  } catch (err) {}
}
function* getAccountCreation({payload = {}}: any) {
  try {
    const {id} = payload
    const res = yield call(AccountAPI.getAccountCreation, {id})
    yield put(AccountActions.setAccountCreation(res))
  } catch (err) {}
}
function* approveAccountCreation({payload = {}}: any) {
  try {
    const {id, version} = payload
    yield call(AccountAPI.approveAccountCreation, {id, version})
    yield put(UIActions.showMessage({type: 'success', message: 'approve success'}))
    yield put(AccountActions.getAccountCreation({id}))
  } catch (err) {}
}
function* rejectAccountCreation({payload = {}}: any) {
  try {
    const {id, version, rejectReason, onSuccess} = payload
    yield call(AccountAPI.rejectAccountCreation, {id, version, rejectReason})
    yield put(UIActions.showMessage({type: 'success', message: 'reject success'}))
    yield put(AccountActions.getAccountCreation({id}))
    onSuccess?.()
  } catch (err) {}
}
function* updateMe() {
  try {
    yield call(AccountAPI.updateMe, {})
  } catch (err) {}
}
function* getMyAccountCreation() {
  try {
    const res = yield call(AccountAPI.getMyAccountCreation)
    yield put(AccountActions.setMyAccountCreation(res))
  } catch (err) {}
}
function* listLegalEntityAccounts() {
  try {
    yield call(AccountAPI.listLegalEntityAccounts, {})
  } catch (err) {}
}
function* listAccounts() {
  try {
    const paging = yield select((state) => _.get(state, ['tableConfig', AccountConstants.LIST_ACCOUNTS, 'paging'], {}))
    const res = yield call(AccountAPI.listAccounts, {paging})
    yield put(AccountActions.setListAccounts({accounts: res.accounts}))
    yield put(
      TableConfigActions.setTableConfig({
        type: AccountConstants.LIST_ACCOUNTS,
        config: {pagination: {total: res.paging?.totalNumber}},
      })
    )
  } catch (err) {}
}
function* getAccountFullInfo({payload = {}}: any) {
  const {id} = payload
  try {
    const res = yield call(AccountAPI.getAccountFullInfo, {accountId: id})
    yield put(AccountActions.setAccountFullInfo(res))
  } catch (err) {
    try {
      const res = yield call(AccountAPI.getUserFullInfo, {userId: id})
      yield put(AccountActions.setAccountFullInfo(res))
    } catch (error) {}
  }
}
function* createSubAccount({payload = {}}: any) {
  try {
    const {subAccountInfo} = payload
    yield call(AccountAPI.createSubAccount, {subAccountInfo})
    // TODO:message
    yield put(UIActions.showMessage({type: 'success', message: 'create success'}))
    history.push('/account/subAccount/success')
  } catch (err) {}
}

function* getLegalEntityContract() {
  try {
    const res = yield call(AccountAPI.getLegalEntityContract)
    yield put(AccountActions.setLegalEntityContract({contractInfo: res.contractInfo}))
  } catch (err) {}
}

function* resendLegalEntityContractOrder() {
  try {
    yield call(AccountAPI.resendLegalEntityContractOrder)
    yield put(UIActions.showMessage({type: 'success', message: 'メールを送信しました'}))
  } catch (err) {}
}

function* changePlan({payload = {}}: any) {
  try {
    const {planId} = payload
    yield call(AccountAPI.changePlan, {planId})
    yield put(UIActions.showMessage({type: 'success', message: '変更が完了しました'}))
  } catch (err) {}
}

export const accountSaga = [
  takeLatest(AccountConstants.REGISTER_START, register),
  takeLatest(AccountConstants.UPGRADE_ME, upgradeMe),
  takeLatest(AccountConstants.VERIFY_UPGRADE_ME, verifyUpgradeMe),
  takeLatest(AccountConstants.GET_ME, getMe),
  takeLatest(AccountConstants.CHANGE_MY_EMAIL, changeMyEmail),
  takeLatest(AccountConstants.VERIFY_EMAIL, verifyEmail),
  takeLatest(AccountConstants.LIST_ACCOUNT_CREATION, listAccountCreation),
  takeLatest(AccountConstants.GET_ACCOUNT_CREATION, getAccountCreation),
  takeLatest(AccountConstants.APPROVE_ACCOUNT_CREATION, approveAccountCreation),
  takeLatest(AccountConstants.REJECT_ACCOUNT_CREATION, rejectAccountCreation),
  takeLatest(AccountConstants.UPDATE_ME, updateMe),
  takeLatest(AccountConstants.GET_MY_ACCOUNT_CREATION, getMyAccountCreation),
  takeLatest(AccountConstants.LIST_LEGAL_ENTITY_ACCOUNTS, listLegalEntityAccounts),
  takeLatest(AccountConstants.LIST_ACCOUNTS, listAccounts),
  takeLatest(AccountConstants.GET_ACCOUNT_FULL_INFO, getAccountFullInfo),
  takeLeading(AccountConstants.CREATE_SUB_ACCOUNT, createSubAccount),
  takeLeading(AccountConstants.GET_LEGAL_ENTITY_CONTRACT, getLegalEntityContract),
  takeLatest(AccountConstants.RESEND_LEGAL_ENTITY_CONTRACT_ORDER, resendLegalEntityContractOrder),
  takeLeading(AccountConstants.CHANGE_PLAN, changePlan),
]
