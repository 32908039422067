import React, {memo} from 'react'
import {useMount} from 'ahooks'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'

import SinglePage from '@/components/SinglePage'
import {AccountActions} from '@/actions'

import _ from 'lodash'

import AccountFormForAdmin from '../../components/AccountFormForAdmin'

const UserDetails = () => {
  const dispatch = useDispatch()
  const {id}: any = useParams()
  const {account} = useSelector((state) => ({
    account: _.get(state, ['account', 'fullInfo', id, 'flat']),
  }))

  useMount(() => {
    id && dispatch(AccountActions.getAccountFullInfo({id}))
  })

  return (
    <div>
      <SinglePage title="ユーザ情報詳細">
        <AccountFormForAdmin account={account} />
      </SinglePage>
    </div>
  )
}

export default memo(UserDetails)
