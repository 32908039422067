import React, {memo} from 'react'
import {Button} from 'antd'
import {useMount, useUnmount, useBoolean} from 'ahooks'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import _ from 'lodash'
import classnames from 'classnames'

import {OrderStatusEnum} from '@/constants/enums'
import {Form, FormItem} from '@/components/Form'
import {UIActions, OrderActions, OtherActions, AccountActions} from '@/actions'
import {AccountConstants, OtherConstants} from '@/store/constants'
import SinglePage from '@/components/SinglePage'
import styles from './styles.module.less'

const Order = () => {
  const {id}: any = useParams()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [isConfirming, {setTrue: setIsConfirming, setFalse: setIsNotConfirming}] = useBoolean(false)
  const {payLoading, order, resendMailLoading} = useSelector((state) => ({
    payLoading: _.get(state, ['loading', OtherConstants.PAY_ORDER]),
    resendMailLoading: _.get(state, ['loading', AccountConstants.RESEND_LEGAL_ENTITY_CONTRACT_ORDER]),
    order: _.get(state, ['order', 'orderDetails', id], {}),
  }))
  const planName = _.get(order, ['content', 'contractPlanContent', 'planName'], '')
  const amountFormatted = `${Number(_.get(order, ['amount'], 0)).toLocaleString('ja-JP', {
    style: 'currency',
    currency: 'JPY',
    currencyDisplay: 'name',
  })}`

  const notCampaignamountFormatted = `${Number(_.get(order, ['amount'], 0) * 2).toLocaleString('ja-JP', {
    style: 'currency',
    currency: 'JPY',
    currencyDisplay: 'name',
  })}`

  const status = _.get(order, ['status'])
  const isNotNewOrder = status !== OrderStatusEnum.NewOrder

  useMount(() => {
    id && dispatch(OrderActions.getOrder({id}))
    dispatch(UIActions.resetBackBreadcrumb({backWay: '/login'}))
  })

  useUnmount(() => {
    dispatch(UIActions.resetBackBreadcrumb({}))
  })

  const setBackBreadcrumbWhenIsComfiming = () => {
    dispatch(
      UIActions.resetBackBreadcrumb({
        backWay: () => {
          setIsNotConfirming()
          dispatch(UIActions.resetBackBreadcrumb({backWay: '/login'}))
        },
      })
    )
  }

  const onFinish = (values: {[field: string]: string}) => {
    if (isConfirming) {
      dispatch(
        OtherActions.payOrder({
          id,
          values,
          // onSuccess: () => {},
          onFail: (errMessage) => {
            dispatch(UIActions.showMessage({type: 'error', message: errMessage}))
          },
        })
      )
    } else {
      setIsConfirming()
      setBackBreadcrumbWhenIsComfiming()
    }
  }

  const resendMail = () => {
    dispatch(AccountActions.resendLegalEntityContractOrder())
  }

  return (
    <div>
      <SinglePage
        title={isConfirming ? 'プラン決済確認画面' : 'プラン決済画面'}
        bottom={
          <div className="flexVertical">
            <Button type="primary" htmlType="submit" form="order_pay" loading={payLoading} disabled={isNotNewOrder}>
              {isConfirming ? '支払う' : '確認'}
            </Button>
            <Button type="link" onClick={resendMail} className={styles.resendMailLink} loading={resendMailLoading}>
              プラン決済用のURLを再発行する
            </Button>
          </div>
        }>
        <Form
          form={form}
          name="order_pay"
          onFinish={onFinish}
          defaultStyle="center"
          className={classnames(isConfirming && styles.confirmForm)}>
          <FormItem.SubTitle title="ご利用内容" style={{left: -15}} withPrefix={false} />
          <Form.Item label="プラン名">
            <span>{planName}</span>
          </Form.Item>
          <Form.Item label="月額料金">
            <span>
              {notCampaignamountFormatted}→キャンペーン中につき50%OFFの{amountFormatted}
            </span>
          </Form.Item>
          <FormItem.SubTitle title="決済情報" style={{left: -15}} withPrefix={false} />
          <FormItem.CardName />
          <FormItem.CardNumber />
          <FormItem.CardLimit />
          <FormItem.CardCode />
        </Form>
      </SinglePage>
    </div>
  )
}

export default memo(Order)
