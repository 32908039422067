import * as Enums from './enums'
import {EnumsText} from './enumsText'
import {Regions} from './regions'

export const FieldRules = {
  tel: /^[0-9]{8,11}$/,
  antiqueLicenseNumber: /^[0-9]{12}$/,
  zipcode: /^[0-9]{7}$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,32}$/,
  cardCode: /^[0-9]{3,4}$/,
  number: /^[0-9]*$/,
}

export const DEFAULT_PAGE_SIZE = 10
export const PAGE_SIZE_RANGE = [10, 20, 30]
// reference: lib/app/widget/WebViewPage.dart
export const APP_JAVASCRIPT_CHANNEL_NAME = {
  CloseWebViwe: 'CloseWebViwe',
}

export const VERITRANS_API_KEY = '0409cb24-e550-46d1-b0c2-cfee6472bea8'

export {Enums, Regions, EnumsText}
